import { GetStaticProps } from 'next';
import { useRouter } from 'next/router';
import Layout from 'components/layout';
import Sections from 'components/sections';
import useStoryblok from 'lib/hooks/useStoryblok';
import { getPageData } from 'lib/storyblok/getPageData';
import { getGlobalData } from 'lib/storyblok/getGlobalData';

export default function DynamicPage({ globalData, pageData, preview }) {
  const router = useRouter();
  const story = useStoryblok(pageData, preview);

  if (router.isFallback) {
    return <div>Loading...</div>;
  }

  return (
    <Layout {...globalData} {...story.content}>
      <Sections sections={story.content.body} />
    </Layout>
  );
}

export const getStaticProps: GetStaticProps = async ({ preview = false, locale: rawLocale }) => {
  const locale = rawLocale === 'default' ? 'en' : rawLocale;
  const pageData = await getPageData(['error-404'], {
    locale,
    preview,
  });

  if (!pageData) {
    return {
      notFound: true,
    };
  }

  const globalData = await getGlobalData({
    locale,
    preview,
  });

  return {
    props: {
      globalData,
      pageData,
      preview,
    },
  };
};
